<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewTrainerSidebarActive" :role-options="roleOptions"
          :plan-options="ss" @refetch-data="refetchData" />
    
        <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
          :role-options="roleOptions" :plan-options="ss" :status-options="statusOptions" /> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex justify-content-between w-100">
          <!-- Per Page -->
          <b-col md="6" class="d-flex mt-1">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="per_page"
              class="per-page-selector d-inline-block mx-50 w-25"
              :options="['5', '10', '20', '50', '100']"
              @input="changePagination()"
            />
            <!-- Search -->
            <div class="d-flex align-items-center justify-content-start">
              <b-button variant="primary" @click="openModal">
                <span
                  class="font-weight-bold text-nowrap"
                  style="
                    font-size: 15px;
                    font-family: 'Public Sans', sans-serif;
                  "
                  >Add New Trainer</span
                >
              </b-button>
            </div>
          </b-col>
          <b-modal
            hide-footer
            v-model="AddNewTrainer"
            no-close-on-backdrop
            centered
            size="lg"
          >
            <validation-observer ref="Rules">
              <b-form ref="myForm">
                <b-row>
                  <b-col md="12" class="d-flex align-items-center flex-column">
                    <h2
                      class="font-weight-bolder"
                      style="
                        font-size: 26px;
                        font-family: 'Public Sans', sans-serif;
                      "
                    >
                      {{
                        ifEdit == true
                          ? "Update Trainer Details"
                          : "Add New Trainer"
                      }}
                    </h2>
                    <p
                      class="font-weight-bold"
                      style="
                        font-size: 15px;
                        font-family: 'Public Sans', sans-serif;
                      "
                    >
                      Updating user details will receive a privacy audit.
                    </p>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-first-name"
                        >Full Name</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Full Name"
                      >
                        <b-form-input
                          v-model="fullname"
                          placeholder="First Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-gender"
                        >Gender</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Gender"
                      >
                        <v-select
                          v-model="gender"
                          label="name"
                          :searchable="false"
                          :options="getOptions('gender')"
                          placeholder="Select gender"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-goal"
                        >Address</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Address"
                      >
                        <b-form-input v-model="address" placeholder="Address" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-first-name"
                        >Create UserID</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="UserID"
                      >
                        <b-form-input
                          type="text"
                          autocomplete="new-text"
                          v-model="UserID"
                          maxlength="20"
                          placeholder="UserID"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-first-name"
                        >Birth Date</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Date of Birth"
                      >
                        <!-- <flat-pickr
                          v-model="dob"
                          style="background-color: transparent"
                          class="form-control "
                          placeholder="DD/MM/YYYY"
                          :config='{
                            dateFormat: "m/d/Y",
                            maxDate: "today"
                          }'
                        /> -->
                        <datepicker 
                          v-model="dob" 
                          inputClass="form-control bg-transparent"
                          format="dd/MM/yyyy"
                          :disabled-dates="disabledDates"
                        ></datepicker>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-height"
                        >Height</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="Height"
                      >
                        <b-form-input
                          v-model="height"
                          maxlength="3"
                          placeholder="158 Cm"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-weight"
                        >Weight</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Weight"
                      >
                        <b-form-input
                          v-model="weight"
                          placeholder="60Kg"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-email"
                        >Email</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|email"
                        name="Email"
                      >
                        <b-form-input
                          v-model="email"
                          placeholder="Email"
                          type="email"
                        />
                        <small v-if="con_email!== email" class="text-danger"
                          >Email and Confirm email must be
                          same.</small
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-email"
                        >Confirm Email</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|email"
                        name="Email"
                      >
                        <b-form-input
                          v-model="con_email"
                          placeholder="Email"
                          type="email"
                          maxlength="50"
                        />
                        <small v-if="con_email!== email" class="text-danger"
                          >Email and Confirm email must be
                          same.</small
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-contact"
                        >Contact</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="Contact"
                      >
                        <b-form-input
                          v-model="contact"
                          placeholder="Contact No."
                          maxlength="15"
                        />
                        <small
                          v-if="con_contact!== contact"
                          class="text-danger"
                          >Contact no. and Confirm contact no. must be
                          same.</small
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-contact"
                        >Confirm Contact</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="Confirm Contact"
                      >
                        <b-form-input
                          id="h-contact"
                          maxlength="15"
                          v-model="con_contact"
                          placeholder="Contact No."
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="con_contact!== contact"
                          class="text-danger"
                          >Contact no. and Confirm contact no. must be
                          same.</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-languag"
                        >Language</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        name="Language"
                      >
                        <b-form-input
                          placeholder="English"
                          disabled
                          :options="getOptions('language')"
                          :searchable="false"
                          label="name"
                          :value="language"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-country"
                        >Country</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Country"
                      >
                        <v-select
                          v-model="country"
                          label="name"
                          :options="getOptions('country')"
                          placeholder="Select Country"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" v-if="!ifEdit">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-contact"
                        >Password</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|password"
                        name="password"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            autocomplete="new-password"
                            v-model="password"
                            :type="passwordIcon"
                            name="Password"
                            placeholder="Password"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePassword"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small v-if="passwordMismatch" class="text-danger"
                          >Password and Confirm Password must be same.</small
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" v-if="!ifEdit">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-contact"
                        >Confirm Password</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|password"
                        name="Confirm"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            v-model="confirm_password"
                            autocomplete="new-password"
                            :type="confirmpasswordIcon"
                            name="Confirm Password"
                            placeholder="Confirm Password"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="ConfirmIcon"
                              @click="toggleConfirmPassword"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small v-if="passwordMismatch" class="text-danger"
                          >Password and Confirm Password must be same.</small
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-goal"
                        >Profile Photo</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Profile"
                      >
                        <b-form-file
                          v-model="profileAttach"
                          placeholder="Upload Photo"
                          @input="uploadDoc($event, 'profileAttach', 'trainer')"
                        ></b-form-file>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1"
                        >ID (Passport, License)</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="ID"
                      >
                        <b-form-file
                          placeholder="Upload ID"
                          v-model="IDAttach"
                          @input="uploadDoc($event, 'IDAttach', 'trainer')"
                        ></b-form-file>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-goal"
                        >Personal Trainer Certificate</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Personal Trainer"
                      >
                        <b-form-file
                          v-model="certiAttach"
                          placeholder="Upload Cerificate"
                          @input="uploadDoc($event, 'certiAttach', 'trainer')"
                        ></b-form-file>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-goal"
                        >PT Insurance</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="PT Insurance"
                      >
                        <b-form-file
                          placeholder="Upload Insurance"
                          v-model="PtInsurance"
                          @input="uploadDoc($event, 'PtInsurance', 'trainer')"
                        ></b-form-file>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col md="12" class="d-flex justify-content-center mt-2">
                    <b-button
                      variant="primary"
                      class="mr-1 font-weight-bold"
                      @click.prevent="handleNewPage($event)"
                      style="
                        font-size: 15px;
                        font-family: 'Public Sans', sans-serif;
                      "
                      :disabled="flag"
                    >
                      Continue
                    </b-button>
                    <b-button
                      type="reset"
                      class="font-weight-bold"
                      style="
                        font-size: 15px;
                        font-family: 'Public Sans', sans-serif;
                      "
                      variant="primary"
                      @click="Close"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
          <b-modal
            v-model="SubmitTrainer"
            no-close-on-backdrop
            hide-footer
            centered
            size="md"
          >
            <validation-observer ref="simpleRules">
              <b-form ref="myFormMain">
                <b-row class="p-1">
                  <b-col md="12" class="mb-1 text-center">
                    <h3 class="font-weight-bolder" style="font-size: 26px">
                      Specialized in Which Area
                    </h3>
                  </b-col>
                  <b-col
                    md="6"
                    v-for="(option, index) in getOptions('specialized')"
                    :key="index"
                    class="mb-1"
                  >
                    <div
                      class="option"
                      :class="{
                        selected: selectedOptions.indexOf(option.id) != -1,
                      }"
                      @click="handleOptionClick(option.id)"
                    >
                      {{ option.name }}
                    </div>
                  </b-col>

                  <b-col md="12" class="font-weight-bolder my-2 text-center">
                    <h3 style="font-size: 26px; font-family: Public Sans">
                      Membership Details
                    </h3>
                  </b-col>
                  <b-col md="12">
                    <label for="input">Select Plans</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Select Plan"
                    >
                      <v-select
                        placeholder="Select Your Plans"
                        label="name"
                        :searchable="false"
                        :options="getOptions('plan')"
                        v-model="plans"
                      ></v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- @click="Congo" -->
                  <b-col md="12" class="d-flex justify-content-center mt-2">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      :disabled='submiting'

                      @click.prevent="handleTrainerSubmit($event)"
                      style="font-family: Public Sans"
                    >
                      {{ ifEdit == true ? "Update" : "Submit" }}
                    </b-button>
                    <b-button
                      type="reset"
                      @click="CloseTrainer"
                      variant="primary"
                      style="font-family: Public Sans"
                    >
                      Back
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>

          <b-col md="6" class="d-flex mt-1 ml-100">
            <div class="w-100 d-flex justify-content-end">
              <div v-if="searchTerm.type == 'dropdown'">
                <v-select
                  style="width: 200px"
                  placeholder="Search..."
                  :options="getOptions(searchTerm.storename)"
                  :label="searchTerm.displayName"
                  class="d-inline-block mr-1"
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <div v-else>
                <b-form-input
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <div class="ml-1">
                <v-select
                  style="width: 150px !important"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :searchable="false"
                  v-model="searchTerm"
                  placeholder="Select"
                  class="per-page-selector d-inline-block mx-50"
                  label="name"
                  tbody-tr-class="custom-table-row"
                  :options="searchTermOptions"
                  @input="handleSearchType()"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :busy="isLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="trainers"
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        thead-class="head"
        :fields="tableColumns"
        @sort-changed="onSortChanged"
      >
        <!-- :sort-by.sync="sortBy" -->
        <!-- :sort-desc.sync="orderBy" -->
        <!-- :sort-desc.sync="isSortDirDesc" -->
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <!-- {{ field.label }} -->
        <template #cell(id)="data">
          <span class="text-primary">
            {{ data.index + 1 }}
          </span>
        </template>
        <template #cell(trainers_customers)="data">
          <b-avatar-group size="32" class="d-flex align-items-center">
            <b-avatar
              v-for="(avatar, index) in data.item.trainers_customers.slice(
                0,
                3
              )"
              :key="index"
              :src="avatar.profilephoto"
              :text="avatar.f_name.substring(0, 1)"
              class="pull-up z-100"
              button
              @click="
                $router.push({ path: `/trainermanagement/${data.item.id}` })
              "
            >
              <!-- variant="button" -->
              <!-- @click="$router.push({ path: `/trainermanagement/${data.item.id}` })" -->
            </b-avatar>
            <b-avatar
              v-if="data.item.trainers_customers.length > 3"
              class="bg-transparent text-secondary"
              style="margin-left: 5px; font-size: 18px"
              button
              @click="
                $router.push({ path: `/trainermanagement/${data.item.id}` })
              "
              >+{{ data.item.trainers_customers.length - 3 }}</b-avatar
            >
          </b-avatar-group>
        </template>
        <!-- <span>{{data.item && data.item.trainers_customers.length > 0 ? data.item.trainers_customers[0].f_name : '' }}</span> -->
        <!-- <span class="mr-1"> -->
        <!-- <b-avatar size="32"
                        :src="data.item && data.item.trainers_customers.length > 0 ? data.item.trainers_customers[0].profilephoto : ''"
                        :text="data.item && data.item.trainers_customers.length > 0 ? data.item.trainers_customers[0].f_name.substring(0, 1) : ''" />
                </span> -->
        <template #cell(f_name)="data">
          <div
            @dblclick="openTrainerProfile(data.item)"
            class="cursor-pointer font-weight-bolder d-flex align-items-center"
            style="font-size: 15px; font-family: 'Public Sans', sans-serif"
          >
            <span class="mr-1">
              <b-avatar
                size="32"
                :src="data.item.profilephoto"
                :text="data.item.f_name.substring(0, 1)"
              />
            </span>
            {{ data.item.f_name }}
          </div>
        </template>
        <template #cell(specialized)="data">
          <div class="d-flex w-100 align-items-center font-weight-bold">
            <div>
              <p
                class="m-0"
                style="font-size: 15px; font-family: 'Public Sans', sans-serif"
              >
                {{ setSepecialization(data.item.all_specialization) }}
              </p>
            </div>
          </div>
        </template>

        <template #cell(phone)="data">
          <div
            class="d-flex align-items-center font-weight-bolder"
            style="font-size: 15px; font-family: 'Public Sans', sans-serif"
          >
            <span>{{ data.item.phone }}</span>
          </div>
        </template>
        <template #cell(Action)="data">
          <div class="d-flex justify-content-start align-items-center">
            <!-- <feather-icon
            icon="MailIcon"
            size="22"
            class="m-1"
            style="cursor: pointer"
          /> -->
            <feather-icon
              icon="EditIcon"
              size="22"
              class="m-1"
              style="cursor: pointer"
              @click="handleEditForm(data.item)"
            />
            <feather-icon
              icon="Trash2Icon"
              size="22"
              class="m-1"
              style="cursor: pointer"
              @click="openAssignTrainerModal(data.item)"
            />
            <!-- @click="removeRow(data.item.id)" -->
            <feather-icon
              icon="EyeIcon"
              size="22"
              class="m-1"
              @click="openProfile(data.item)"
              style="cursor: pointer"
            />
          </div>
        </template>
      </b-table>
      <div>
        <b-modal
          hide-footer
          v-model="assignCustomerTrainer"
          no-close-on-backdrop
          centered
          size="md"
        >
          <b-tabs pills>
            <b-tabs pills>
              <b-tab title="All Customer Assign">
                <b-row class="p-2">
                  <b-col md="12" class="d-flex align-items-center flex-column">
                    <h2
                      style="font-size: 26px"
                      class="font-weight-bolder lableTitle"
                    >
                      Clients assigned to trainers
                    </h2>
                    <p class="labelTitle">
                      "Assign to trainers with our gym management software."
                    </p>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <label class="font-weight-bolder mt-1" for="h-trainer"
                        >Select Trainer</label
                      >

                      <b-form-input
                        id="h-trainer"
                        v-model="newSearch"
                        @input="handleTrainerSearch()"
                        placeholder="Name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <h2 class="font-weight-bold text-dark mr-1">
                      {{ assignTrainerData.length }} Trainers
                    </h2>
                  </b-col>
                  <b-col md="12">
                    <!-- Loading indicator outside the loop -->
                    <div
                      v-if="isSearchLoading"
                      style="height: 400px"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-spinner label="Loading..."></b-spinner>
                    </div>
                    <div v-else>
                      <!-- Loop through totalTrainerData -->
                      <div
                        v-for="(item, index) in assignTrainerData"
                        :key="index"
                        class="mt-2"
                      >
                        <div class="w-100">
                          <div class="d-flex w-100 align-items-center">
                            <div class="mr-1">
                              <b-avatar size="32" :src="item.profilephoto" />
                            </div>
                            <div class="w-100">
                              <h5 class="m-0 lableTitle">{{ item.f_name }}</h5>
                              <small>
                                {{ setSepecialization(item.all_specialization) 
                                
                              }}</small>
                            </div>
                            <div
                              class="d-flex w-100 align-items-center justify-content-end"
                            >
                              <b-button
                                variant="primary"
                                :disabled='submiting'
                                @click="handleSubmitForm(item.id)"
                              >
                                <span>Select</span>
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab
                title="Particular Customer Assign"
                value="particularCustomer"
              >
                <validation-observer ref="customerRules">
                  <b-row class="p-2">
                    <b-col
                      md="12"
                      class="d-flex align-items-center flex-column"
                    >
                      <h2
                        style="font-size: 26px"
                        class="font-weight-bolder lableTitle"
                      >
                        Clients assigned to trainers
                      </h2>
                      <p class="labelTitle">
                        "Assign to trainers with our gym management software."
                      </p>
                    </b-col>
                    <b-col md="12">
                      <h2 class="font-weight-bold text-dark mr-1">
                        {{ TrainerCustomers.length }} Clients
                      </h2>
                    </b-col>
                    <b-col
                      md="12"
                      v-for="(item, index) in TrainerCustomers"
                      :key="index"
                      class="mt-2"
                    >
                      <div class="w-100">
                        <div
                          v-if="isDataLoading"
                          style="height: 400px"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <b-spinner label="Loading..."></b-spinner>
                        </div>
                        <div v-else class="d-flex w-100 align-items-center">
                          <div class="mr-1">
                            <b-avatar size="32" :src="item.profilephoto" />
                          </div>
                          <div class="w-100 font-weight-bolder">
                            <h5 class="m-0 lableTitle">
                              {{ item.f_name }}
                            </h5>
                            <small>{{
                              item &&
                              item.customer_goal &&
                              item.customer_goal.goal
                                ? item.customer_goal.goal.name
                                : ""
                            }}</small>
                          </div>
                          <div
                            class="d-flex w-100 align-items-center justify-content-end"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="'Select Trainer ' + (index + 1)"
                              rules="required"
                            >
                              <v-select
                                v-model="item.selectedTrainer"
                                style="width: 200px"
                                placeholder="Select Trainer"
                                :options="dropdownTrainers_select"
                                :clearable="false"
                                label="f_name"
                                class="d-inline-block ml-5"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      v-if="TrainerCustomers.length > 0"
                      md="12"
                      class="d-flex justify-content-center mt-3"
                    >
                      <b-button
                        type="submit"
                        variant="primary"
                        class="button mr-1"
                        @click="submitSelectedCustTrainer()"
                      >
                        Submit
                      </b-button>
                      <b-button
                        type="reset"
                        variant="primary"
                        class="button"
                        @click="Close()"
                      >
                        Cancel
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
            </b-tabs>
          </b-tabs>
        </b-modal>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <div class="w-100 d-flex justify-content-between p-1">
            <!-- Pagination -->
            <div>
              <span class="text-nowrap">
                Showing {{ pagination.from }} to {{ pagination.to }} of
                {{ pagination.total }}</span
              >
            </div>
            <b-pagination
              :total-rows="paginationTotal"
              :per-page="per_page"
              v-model="paginationValue"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="changePagination()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
              <!-- <div>
                <button
                  @click="goToPage(paginationValue - 1)"
                  :disabled="paginationValue === 1"
                >
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </button>
                <button
                  @click="goToPage(paginationValue + 1)"
                  :disabled="paginationValue * per_page >= paginationTotal"
                >
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </button>
              </div> -->
            </b-pagination>
          </div>
        </b-row>
      </div>
    </b-card>
  </div>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BProgress,
  BFormGroup,
  BFormFile,
  BForm,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BAvatarGroup,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";

import axios from "../../components/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import _ from "lodash";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    Datepicker,
    ValidationProvider,
    ValidationObserver,
    BCard,
    moment,
    BAvatarGroup,
    BSpinner,
    required,
    BRow,
    BTabs,
    BTab,
    BForm,
    BInputGroupAppend,
    BInputGroup,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    vSelect,
    BFormFile,
    flatPickr,
  },
  data() {
    return {
      // tableColumns: ['ID', 'Trainer', 'Clients', 'SpecializedInWhichArea', 'Contact', 'Actions'],
      tableColumns: [
        // { key: 'id', label: '#', sortable: true },
        // { key: 'invoiceStatus', sortable: true },
        {
          key: "id",
          label: "ID",
        },
        {
          key: "f_name",
          label: "Trainers",
          sortable: true,
        },
        {
          key: "trainers_customers",
          label: "Clients",
        },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        {
          key: "specialized",
          label: "Specialized In Which Area",
          type: "dropdown",
          displayName: "name",
        },
        // { key: 'balance', sortable: true },
        {
          key: "phone",
          label: "Contact",
          sortable: true,
        },
        { key: "Action", label: "Actions" },
      ],
      totalTairner: [],
      totalTrainerData: [],

      AllTrainer: [],
      dropdownTrainers:[],
      dob: "",
      // activeTab: "",
      newSearch: "",
      isSearchLoading: false,
      TrainerCustomers: [],
      assignCustomerTrainer: false,
      columnFilters: {},
      isDataLoading: false,
      searchTerm: { name: "Trainer", value: "trainer" },
      search: "",
      trainerSearch: "",
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      // isSortDirDesc: true,
      congrate: false,
      SubmitTrainer: false,
      AddNewTrainer: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      fullname: "",
      passwordMismatch: false,
      password: "",
      selectedOptions: [],
      confirm_password: "",
      passwordIcon: "password",
      confirmpasswordIcon: "password",
      gender: "",
      isLoading: false,
      address: "",
      // UserID: "",
      height: "",
      weight: "",
      pagination: "",
      email: "",
      con_email:'',
      con_contact:'',
      contact: "",
      language: "English",
      langOption: [],
      country: "",
      profileAttach: "",
      IDAttach: "",
      certiAttach: "",
      PtInsurance: "",
      insurance: "",
      weightloss: "",
      condition: "",
      strenth: "",
      plans: "",
      originalDate: moment().format("DD/MM/YYYY"),
      flag: false,
      Title: "",
      files: "",
      plansOption: [],
      genderOption: [],
      ContryOption: [],
      specializedOption: [],
      trainers: [],
      ifEdit: false,
      id: 0,
      trainer_id: 0,
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      params: {},
      assignTrainerId: 0,
      lastTrainer: [],
      isLoading: false,
      assignTrainerData: [],
      submiting:false,
      disabledDates:{
        from: '',
      },
      dropdownTrainers_select:[],
      searchTermOptions: [
        { name: "Trainer", value: "trainer" },
        {
          name: "Specialized",
          value: "specialized",
          type: "dropdown",
          displayName: "name",
          storename: "specialized",
        },
      ],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ConfirmIcon() {
      return this.confirmpasswordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    setSepecialization() {
      return (item) => {
        let arr = item.map((inner) => {
          return inner.specialization ? inner.specialization.name : "";
        });
        return arr.filter(item => item).join(', ')
      };
    },
  },
  watch: {
    password() {
      this.validatePasswordMatch();
    },
    confirm_password() {
      this.validatePasswordMatch();
    },
  },

  mounted() {
    this.getTrainerData();
    this.getTrainers();
    let year = new Date().getFullYear() -10
    this.disabledDates.from =new Date(new Date().setFullYear(year));
  },
  methods: {
    async getTrainers() {
      this.isSearchLoading = true;
      // this.params = {}
      let params = {};
      params.search = this.newSearch;
      params.type='trainer'
      // this.params.per_page = this.per_page
      //  let params = `?page=${this.paginationValue}&per_page=${this.per_page}${
      //   this.search ? "&search=" + this.search : ""
      // }${
      //   this.search && this.searchTerm ? "&type=" + this.searchTerm.value : ""
      // }`;
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/get-all-trainer`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.totalTrainerData = json?.data.data;
          if (this.trainer_id) {
            this.assignTrainerData = this.totalTrainerData.filter(
              (trainer) => trainer.id !== this.trainer_id
            );
          }
          if(this.dropdownTrainers.length ==0){
            this.dropdownTrainers = JSON.parse(JSON.stringify(this.totalTrainerData));
          }
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getTrainerData();
    },
    handleSearchType() {
      this.search = "";
      this.getTrainerData();
    },
    goToPage(page) {
      if (
        page >= 1 &&
        page <= Math.ceil(this.paginationTotal / this.per_page)
      ) {
        this.paginationValue = page;
        this.getTrainerData();
      }
    },
    validatePasswordMatch() {
      if (this.password !== this.confirm_password) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    },
    changePagination() {
      this.getTrainerData();
    },
    handleOptionClick(id) {
      let index = this.selectedOptions.indexOf(id);
      if (index == -1) {
        this.selectedOptions.push(id);
      } else {
        this.selectedOptions.splice(index, 1);
      }
    },
    toggleConfirmPassword() {
      this.confirmpasswordIcon =
        this.confirmpasswordIcon === "password" ? "text" : "password";
    },
    togglePassword() {
      this.passwordIcon =
        this.passwordIcon === "password" ? "text" : "password";
    },
    async getTrainerData(id) {
      this.isDataLoading = false;
      this.isLoading = true;
      // this.params = {}
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      } else if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      // this.params.per_page = this.per_page
      //  let params = `?page=${this.paginationValue}&per_page=${this.per_page}${
      //   this.search ? "&search=" + this.search : ""
      // }${
      //   this.search && this.searchTerm ? "&type=" + this.searchTerm.value : ""
      // }`;
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/all`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.trainers = json?.data.data.data;
          this.pagination = json?.data.data;
          this.paginationTotal = this.pagination.total;
          this.paginationStart = this.pagination.from;
          this.paginationEnd = this.pagination.to;
          this.totalTairner = json?.data.data.data.total;

          const paramId = parseInt(id);
          const filteredTrainers = json?.data.data.data.filter(
            (trainer) => trainer.id !== paramId
          );
          this.AllTrainer = filteredTrainers;
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // async getTotalTrainerData() {
    //   this.isSearchLoading = true;
    //   let newParam = `?page=1&per_page=10000${
    //     this.newSearch ? "&search=" + this.newSearch : ""
    //   }`;
    //   await axios({
    //     method: "GET",
    //     url: `${this.baseApi}trainer/all${newParam}`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //   })
    //     .then((json) => {
    //       this.totalTrainerData = json?.data.data.results.data;

    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       console.log(error, "error");
    //     })
    //     .finally(() => {
    //       this.isSearchLoading = false;
    //     });
    // },

    handleTrainerSearch: _.debounce(function () {
      this.getTrainers();
    }, 1000),
    handleSearch: _.debounce(function () {
      this.getTrainerData();
    }, 1000),

    async removeRow(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            if (!item) {
              if (this.assignTrainerId) {
                this.handleAssignForm(this.assignTrainerId);
              } else {
                this.handleTrainerSelectForm();
              }
            }
            await axios({
              method: "Delete",
              url: `${this.baseApi}trainer/delete/${this.trainer_id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then((res) => {
                this.$swal({
                  title: "Deleted",
                  text: "Trainer deleted successfully",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.getTrainerData();
                this.getTrainers()
                // this.$forceUpdate();
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })

        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },

    async uploadDoc(event, variable, name) {
      this.flag = true;
      if (event) {
        const formData = new FormData();
        formData.append("url", event);
        formData.append("type", "image");
        await axios({
          method: "POST",
          url: `${this.baseApi}attachments/uploadattachment`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: formData,
        }).then(async (response) => {
          //   console.log(response.data.data.url)
          //   let data = {
          //     url: response.data.data.url,
          //     admin_id: JSON.parse(localStorage.getItem('userData')).id,
          //     status: 1
          //   }
          this[variable] = response.data.data.url;
          this.flag = false;
        });
      }
    },
    openProfile(item) {
      const userId = item.id;
      this.$router.push({ path: `/trainermanagement/${userId}` });
    },
    openTrainerProfile(item) {
      const UserId = item.id;
      this.$router.push({ path: `/trainerprofile/${UserId}` });
    },
    CloseTrainer() {
      this.SubmitTrainer = false;
      this.AddNewTrainer = true;
    },
    openModal() {
      this.AddNewTrainer = true;
      this.ifEdit = false;
      this.fullname = "";
      this.gender = "";
      this.address = "";
      this.goal = "";
      this.height = "";
      this.weight = "";
      this.email = "";
      this.con_email = "";
      this.contact = "";
      this.con_contact = "";
      this.language = "";
      this.country = "";
      this.password = "";
      this.confirm_password = "";
      this.id = 0;
      this.profileAttach = "";
      this.IDAttach = "";
      this.certiAttach = "";
      this.PtInsurance = "";
      this.specialized = "";
      this.plans = "";
      // this.UserId=''
      this.dob = moment().subtract(16,'year')._d

    },
    handleNewPage() {
      this.$refs.Rules.validate().then(async (success) => {
        if (success && !this.passwordMismatch && this.email == this.con_email && this.contact == this.con_contact) {
          if (this.AddNewTrainer) {
            this.AddNewTrainer = false;
          }
          this.SubmitTrainer = true;
        }
        else{
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    handleTrainerSubmit() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success === false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.handleForm();

          // this.congrate = true
        }
      });
    },
    async getTrainerProfileData() {
      this.isSideDataLoading = true;
      // this.isLoading = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/show/${this.trainer_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: this.params,
      })
        .then((json) => {
          this.TrainerCustomers = json.data.data.trainer.trainers_customers;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isSideDataLoading = false;
          // this.isLoading = false;
        });
    },
    async handleEditForm(item) {
      this.AddNewTrainer = true;
      this.openModal()
      this.ifEdit = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/show/${item.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((res) => {
          let data = res?.data?.data.trainer;
          let planData =
            res?.data?.data.membershipDetail &&
            res?.data?.data.membershipDetail.length > 0
              ? res?.data?.data.membershipDetail[0].plan
              : "";
          this.AddNewTrainer = true;
          this.fullname = data.f_name;
          this.gender = data.gender;
          this.address = data.address;
          this.goal = data.goal;
          this.height = data.height;
          this.weight = data.weight;
          this.email = data.email;
          this.con_email = data.email;
          this.contact = data.phone;
          this.con_contact = data.phone;
          this.language = "English";
          this.country = data.country;
          this.plans = planData;
          this.password = "";
          this.confirm_password = "";
          this.id = data.id;
          // this.UserID = data.username;
          this.profileAttach = data.profilephoto;
          this.IDAttach = data.idproof;
          this.certiAttach = data.ptcertificate;
          this.PtInsurance = data.ptinsurance;
          // this.specialized = data.specialized.name;
          (this.dob =
            data.dob && data.dob !== "0000-00-00"
              ? moment(data.dob)._d
              : ""),
            (this.selectedOptions = data.all_specialization
              ? data.all_specialization.map((item) => {
                  return item.specialization_id;
                })
              : []);
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
    },
    async handleForm() {
      let endDate;

      switch (this.plans.id) {
        case 1:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(1, "month")
            .format("YYYY/MM/DD");
          break;
        case 2:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(3, "months")
            .format("YYYY/MM/DD");
          break;
        case 3:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(6, "months")
            .format("YYYY/MM/DD");
          break;
        case 4:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(9, "months")
            .format("YYYY/MM/DD");
          break;
        case 5:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(12, "months")
            .format("YYYY/MM/DD");
          break;
        default:
          // Handle other cases or set a default behavior
          break;
      }
      this.submiting = true
      const customerData = {
        f_name: this.fullname,
        gender: this.gender ? this.gender.id : null,
        address: this.address,
        height: this.height,
        weight: this.weight,
        email: this.email,
        phone: this.contact,
        language: "English",
        country: this.country ? this.country.id : null,
        password: this.password,
        confirm_password: this.confirm_password,
        profilephoto: this.profileAttach,
        idproof: this.IDAttach,
        ptcertificate: this.certiAttach,
        ptinsurance: this.PtInsurance,
        // specialized: this.selectedOption,
        // username: this.UserID,
        plan_id: this.plans ? this.plans.id : null,
        startdate: moment().format("YYYY-MM-DD"),
        enddate: endDate,
        dob: moment(this.dob).format("YYYY-MM-DD"),
        specialization: this.selectedOptions,
      };
      const requestOptions = {
        method: `${this.ifEdit ? "put" : "post"}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: this.ifEdit
          ? `${this.baseApi}trainer/update/${this.id}`
          : `${this.baseApi}trainer/add`,
        data: JSON.stringify(customerData),
      };
      const response = await axios(requestOptions)
        .then((response) => {
          if (response.data.success === false) {
            // Display warning message for failure
            this.AddNewTrainer = true;
            this.SubmitTrainer = false;
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: response.data.message
                ? `${response.data.message}`
                : "Please fill in all fields",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else if (response.data.success === true) {
            this.$swal({
              title: "Submitted",
              text: this.ifEdit
                ? "Trainer Updated Successfully"
                : " Trainer Created Successfully",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.$store.dispatch("app/getAllTrainer");
            this.AddNewTrainer = false;
            this.SubmitTrainer = false;
            this.selectedOptions = [];
            this.getTrainerData();
          }
          // this.$forceUpdate();
        })
        .catch((errors) => {
          this.AddNewTrainer = true;
          this.SubmitTrainer = false;
          this.$swal({
            title: "Error",
            text: errors.response.errors ? `${errors.response.errors}` : "",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }).finally(()=>{
          this.submiting = false
        })
    },

    async handleSubmitForm(id) {
      this.assignTrainerId = id;
      this.removeRow();
    },
    async handleAssignForm(id) {
      const customerData = {
        newtrainer_id: id,
        oldtrainer_id: this.trainer_id,
      };
      this.submiting=true
      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/assignnew-trainer`,
        data: JSON.stringify(customerData),
      };

      await axios(requestOptions)
        .then(() => {
          this.assignCustomerTrainer = false;

          this.$forceUpdate();
        })
        .catch((error) => {
          let string = "";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            string = error.response.data.message;
          }
          this.$swal({
            title: "Error",
            html: string,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          console.error("Error adding customer:", error);
        })
        .finally(()=>{
          this.submiting=false
        })
    },

    async submitSelectedCustTrainer() {
      this.$refs.customerRules.validate().then(async (success) => {
        if (success) {
          this.assignTrainerId = 0;

          this.removeRow();
          // this.handleTrainerSelectForm();
          // this.submitSelectedCustTrainer();
        } else {
          // Validation failed, display an error message or take appropriate action
          this.$swal({
            title: "Validation Error",
            text: "Please fill in all required fields correctly.",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },

    async handleTrainerSelectForm() {
      // const oldTrainerId = parseInt(this.$route.params.id);
      let arr = this.TrainerCustomers.map((item) => {
        return {
          client_id: item.id,
          trainer_id: item.selectedTrainer ? item.selectedTrainer.id : 0,
        };
      });
      const customerData = {
        // oldTrainerId: this.trainer_id,
        assignClient: arr,
      };

      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}trainer/individual-assign`,
        data: JSON.stringify(customerData),
      };

      try {
        await axios(requestOptions);

        this.AddNewTrainer = false;
        this.assignCustomerTrainer = false;
        this.$router.push({ path: `/trainermanagement` });
        this.$forceUpdate();
      } catch (error) {
        let errorMessage = "";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        this.$swal({
          title: "Error",
          html: errorMessage || "An error occurred.",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.error("Error assigning trainer:", error);
      }
    },
    openAssignTrainerModal(item) {
      if (item.trainers_customers && item.trainers_customers.length == 0) {
        this.trainer_id = item.id;
        this.removeRow(item);
        // console.log(this.trainer_id)
      } else {
        this.assignTrainerData = this.totalTrainerData.filter(
          (trainer) => trainer.id !== item.id
        );
        this.dropdownTrainers_select =this.dropdownTrainers.filter(
          (trainer) => trainer.id !== item.id
        );
        // this.lastTrainer.push(item)
        // this.totalTrainerData = filteredTrainerData.concat(this.lastTrainer);
        this.trainer_id = item.id;
        this.getTrainerProfileData();
        this.assignCustomerTrainer = true;
      }
    },
    resetModal() {
      this.AddNewTrainer = false;
      this.ifEdit = false;
      this.fullname = "";
      this.gender = "";
      this.goal = "";
      this.height = "";
      this.weight = "";
      this.con_email = "";
      this.contact = "";
      this.con_contact = "";
      this.language = "";
      this.country = "";
      this.plans = "";
      this.password = "";
      this.confirm_password = "";
      this.id = "";
      this.dob = moment().subtract(16,'year')._d
      // this.UserID = "";
    },
    Close() {
      this.AddNewTrainer = false;
      this.assignCustomerTrainer = false;
    },
    Congo() {
      if (this.SubmitTrainer) {
        this.SubmitTrainer = false;
      }
      this.congrate = true;
    },
    Congratulation() {
      this.congrate = false;
    },
  },
};
</script>
<style>
.avatar-container {
  display: flex;
  align-items: center;
}

.remaining-count {
  margin-left: 5px;
  font-weight: bold;
}

.option {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
}

.option.selected {
  border: 1px solid #7367f0;
  color: #000;
}
</style>
../../components/axios../../components/nav/axios